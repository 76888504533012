<template>
  <div v-if="updateData != null">
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Старшая версия">
        <b-form-input v-model="$v.updateData.major.$model" required="required"
                      :type="'number'"
                      :state="$v.updateData.major.$dirty ? !$v.updateData.major.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Младшая версия">
        <b-form-input v-model="$v.updateData.minor.$model" required="required"
                      :type="'number'"
                      :state="$v.updateData.minor.$dirty ? !$v.updateData.minor.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Версия патча">
        <b-form-input v-model="$v.updateData.patch.$model" required="required"
                      :type="'number'"
                      :state="$v.updateData.patch.$dirty ? !$v.updateData.patch.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Тип обновления">
        <b-form-select :text="creating ? 'Выберите тип обновления' : updateData.type"
                       v-model="$v.updateData.type.$model"
                       :state="$v.updateData.type.$dirty ? !$v.updateData.type.$error : null"
                       :disabled="!creating || !isAdmin" block variant="primary" required="required">
          <option value="MAJOR">MAJOR</option>
          <option value="MINOR">MINOR</option>
          <option value="PATCH">PATCH</option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Платформа обновления">
        <b-form-select :text="creating ? 'Выберите платформу обновления' : updateData.platform"
                       v-model="$v.updateData.platform.$model"
                       :state="$v.updateData.platform.$dirty ? !$v.updateData.platform.$error : null"
                       :disabled="!creating || !isAdmin" block variant="primary" required="required">
          <option value="ANDROID">Android</option>
          <option value="IOS">iOS</option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Наименование обновления">
        <b-form-input v-model="$v.updateData.title.$model"
                      :state="$v.updateData.title.$dirty ? !$v.updateData.title.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Дата и время создания обновления" v-if="!creating">
        <b-form-input v-model="new Date($v.updateData.releaseDate.$model).toLocaleString()"
                      :state="$v.updateData.releaseDate.$dirty ? !$v.updateData.releaseDate.$error : null"
                      :readonly="true">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Перечень изменений">
        <b-form-textarea v-model="$v.updateData.notes.$model"
                         :state="$v.updateData.notes.$dirty ? !$v.updateData.notes.$error : null"
                         :readonly="!creating || !isAdmin"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group v-if="creating">
        <b-form-checkbox v-model="$v.updateData.sendPush.$model" :unchecked-value="true" :value="true"
                         :state="$v.updateData.sendPush.$dirty ? !$v.updateData.sendPush.$error : null">
          Отправить пуш-уведомление о появлении нового обновления
        </b-form-checkbox>
      </b-form-group>
      <b-form-group></b-form-group>
      <b-button variant="primary" class="w-100" type="submit" :disabled="submitting || $v.updateData.$anyError"
                v-if="creating">
        Создать
      </b-button>
      <b-button class="w-100" v-bind:to="'/'" v-if="!creating">
        Назад
      </b-button>
      <b-alert class="mt-2" variant="info" :show="submitting">
        Создание...
      </b-alert>
    </b-form>
  </div>
  <div v-else>

  </div>
</template>
<style>
.img-wrapper img {
  max-width: 100%;
  height: auto;
}
</style>
<script>
import {required} from "vuelidate/lib/validators";
import update from '../../modules/update';
import {BIconTrash} from 'bootstrap-vue';
import {mapGetters} from "vuex";

export default {
  components: {
    'b-icon-trash': BIconTrash
  },
  mounted() {
    if (this.$route.params.platform != null
        && this.$route.params.major != null
        && this.$route.params.minor != null
        && this.$route.params.patch != null) {
      this.reloadUpdateData(this.$route.params.platform, this.$route.params.major, this.$route.params.minor, this.$route.params.patch);
    } else {
      this.creating = true;
      this.updateData = {
        major: null,
        minor: null,
        patch: null,
        title: null,
        releaseDate: null,
        notes: null,
        type: null,
        platform: null,
        sendPush: false,
      };
    }
  },
  data() {
    return {
      submitting: false,
      updateData: null
    };
  },
  props: {
    creating: {
      type: Boolean,
      default: false
    },
  },
  validations() {
    let updateDataValidation = {
      major: {
        required
      },
      minor: {
        required
      },
      patch: {
        required
      },
      type: {
        required
      },
      platform: {
        required
      },
      sendPush: {},
      releaseDate: {},
      title: {},
      notes: {},
    };
    return {
      updateData: updateDataValidation
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    onSubmit() {
      this.$v.updateData.$touch();
      if (this.submitting || this.$v.updateData.$anyError) {
        return;
      }
      this.submitting = true;
      update.createUpdate(this.updateData)
          .then(() => {
            console.log('Обновление создано');
          })
          .then(() => {
            this.reloadUpdateData(this.updateData.platform, this.updateData.major, this.updateData.minor, this.updateData.patch);
            this.$router.push(`/${this.updateData.platform}/${this.updateData.major}.${this.updateData.minor}.${this.updateData.patch}`);
            this.$bvToast.toast('Обновление успешно создано', {
              variant: 'success',
              solid: true,
              noCloseButton: true
            });
          })
          .catch((e) => {
            if (e.isAxiosError) {
              this.$bvToast.toast('Возникла неизвестная ошибка при загрузке', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    reloadUpdateData(platform, major, minor, patch) {
      return update.getSingle(platform, major, minor, patch)
          .then(resp => {
            this.updateData = resp.data;
            return Promise.resolve(this.updateData);
          });
    }
  }

}
</script>
