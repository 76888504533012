import {AXIOS} from './backend-config';

export default {
    getUpdatesList(pageSize, pageNumber) {
        return AXIOS.get('/update/list', {
            params: {
                pageSize,
                pageNumber
            }
        })
    },
    getSingle(platform, major, minor, patch) {
        return AXIOS.get(`/update/${platform}/${major}.${minor}.${patch}`);
    },
    createUpdate(updateInfo) {
        return AXIOS.post('/update/create', updateInfo);
    },
    deleteUpdate(platform, major, minor, patch) {
        return AXIOS.delete(`/update/${platform}/${major}.${minor}.${patch}`);
    }
}
