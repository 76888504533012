<template>
  <div v-if="isRolesEmpty">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <div v-if="isAdmin" class="d-flex">
      <router-link to="/create" class="btn btn-primary mb-2">Создать обновление</router-link>
    </div>
    <b-table
        hover
        responsive
        :items="getUpdateDataTable"
        :fields="fields"
        :current-page="updatesList.currentPage"
        ref="updates_table"
        :per-page="updatesList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Обновления отсутствуют</h6>
      </template>
      <template v-slot:cell(platform)="row">
        {{ row.item.platform }}
      </template>
      <template v-slot:cell(version)="row">
        {{ row.item.major + '.' + row.item.minor + '.' + row.item.patch }}
      </template>
      <template v-slot:cell(title)="row">
        {{ row.item.title != null ? row.item.title : 'Без названия' }}
      </template>
      <template v-slot:cell(releaseDate)="row">
        {{ row.item.releaseDate != null ? new Date(row.item.releaseDate).toLocaleString() : 'Дата отуствует' }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button class="" variant="primary"
                  title="Просмотр"
                  @click="prepareDetailPage(row.item)">
          <b-icon-eye-fill font-scale="0.75"></b-icon-eye-fill>
        </b-button>
        <b-button class="ml-1" variant="primary"
                  v-if="isAdmin"
                  title="Удалить"
                  @click="deleteUpdate(row.item)">
          <b-icon-trash font-scale="0.75"></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="updatesList.perPage"
            class="text-light border-secondary bg-secondary"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center d-none d-lg-block mb-2">записей на страницу</span>
      <b-pagination
          v-model="updatesList.currentPage"
          class="my-0 ml-auto"
          :total-rows="updatesList.totalCount"
          :per-page="updatesList.perPage"
          aria-controls="template-table"
          @input="getUpdateDataTable"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import updatesApi from '../../modules/update';
import {BIconPencil, BIconPlus, BIconTrash, BIconDash} from 'bootstrap-vue';
import {mapGetters} from "vuex";

export default {
  name: 'user-list',
  components: {
    'b-icon-pencil': BIconPencil,
    'b-icon-plus': BIconPlus,
    'b-icon-dash': BIconDash,
    'b-icon-trash': BIconTrash
  },
  data() {
    return {
      fields: [
        {
          key: 'platform', label: 'Платформа'
        },
        {
          key: 'version', label: 'Версия'
        },
        {
          key: 'title', label: 'Название'
        },
        {
          key: 'releaseDate', label: 'Дата создания'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      updatesList: {
        perPage: 20,
        currentPage: 1,
        totalCount: 0,
      },
      selectedUserId: null
    };
  },
  computed: {
    ...mapGetters([
        "isAdmin",
        "isRolesEmpty"
    ])
  },
  methods: {
    getUpdateDataTable(context) {
      return updatesApi.getUpdatesList(context.perPage, context.currentPage)
          .then(resp => {
            this.updatesList.totalCount = resp.data.totalElements;
            return resp.data.updates;
          });
    },
    deleteUpdate(update) {
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить обновление?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              updatesApi.deleteUpdate(update.platform, update.major, update.minor, update.patch)
                  .then(() => {
                    this.deleteError = null;
                    return this.$refs.updates_table.refresh();
                  })
                  .catch(error => {
                    this.deleteError = error;
                  });
            }
          })
          .catch(err => {
            this.deleteError = err;
          });
    },
    prepareDetailPage(update) {
      this.$router.push(`/${update.platform}/${update.major}.${update.minor}.${update.patch}`);
    }
  }
}
</script>
